.plugs {
  animation: fadeIn 1.4s var(--ease-out-cubic) forwards;
  left: 18px;
  opacity: 0;
  position: absolute;
  top: 12px;
  z-index: 10;

  @include mq($from: desktop) {
    position: fixed;
  }

  &-link {
    align-items: center;
    display: flex;
    padding: 12px;
    text-decoration: none;
    transition: all 0.35s var(--ease-out-cubic);

    &:hover {
      .plugs-name {
        opacity: 1;
        transform: none;
      }

      .plugs-icon {
        opacity: 0.75;
      }
    }
  }

  &-name {
    color: white;
    font-size: 16px;
    margin-left: 12px;
    opacity: 0;
    transform: translateY(-20%);
    transition: all 0.35s var(--ease-out-cubic);
  }

  &-icon {
    transition: all 0.35s var(--ease-out-cubic);
  }
}
