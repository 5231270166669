.work {
  min-height: 75vh;
  padding: 60px 0;

  @include mq($from: desktop) {
    min-height: 100vh;
  }

  &-header {
    margin-bottom: 18px;
  }

  &-group {
    border-bottom: 2px solid white;
    opacity: 0;
    transform: translateY(50%);
    transition: all 1s var(--ease-in-out-cubic);

    &.js-scroll-animate {
      opacity: 1;
      transform: none;

      .work-group-header::after {
        transform: scaleY(0);
      }
    }

    &[data-expanded='true'] {
      .work-group-content {
        opacity: 1;
        transform: none;
      }

      svg {
        transform: rotate(135deg);
      }
    }

    &-content {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transform: translateY(5%);
      transition: height 0.6s var(--ease-in-out-cubic),
        opacity 0.6s 0.35s var(--ease-out-cubic),
        transform 0.6s 0.35s var(--ease-out-cubic);

      @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
      }
    }

    &-header {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      position: relative;

      &::after {
        background: var(--background-color);
        content: '';
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transform-origin: center bottom;
        transition: all 1s var(--ease-in-out-cubic);
        width: 100%;
        z-index: 2;
      }

      svg {
        opacity: 0.5;
        transition: all 0.5s var(--ease-out-cubic);
      }

      &:hover {
        svg {
          opacity: 1;
        }

        .work-group-name {
          &::before {
            opacity: 1;
            transform: none;
          }

          &::after {
            transform: scaleY(0) rotateX(180deg);
          }
        }
      }
    }

    &-name {
      display: inline-block;
      font-size: 42px;
      line-height: 1;
      margin: 24px 0;
      perspective: 600px;
      position: relative;
      transition: transform 0.5s var(--ease-out-cubic);

      @include mq($from: tablet) {
        font-size: 60px;
        margin: 36px 0;
      }

      @include mq($from: largeDesktop) {
        font-size: 72px;
        margin: 40px 0;
      }

      span {
        visibility: hidden;
      }

      &::before,
      &::after {
        bottom: 0;
        color: white;
        content: attr(data-name);
        font-size: 42px;
        left: 0;
        line-height: 1;
        position: absolute;
        transform-origin: center bottom;

        @include mq($from: tablet) {
          font-size: 60px;
        }

        @include mq($from: largeDesktop) {
          font-size: 72px;
        }
      }

      &::after {
        transition: all 0.6s var(--ease-in-out-cubic);
      }

      &::before {
        transform: scaleY(0) rotateX(-180deg);
        transition: all 0.6s var(--ease-out-cubic);
      }
    }

    &-info,
    &-body {
      margin-top: 18px;
    }

    &-body {
      margin-top: 36px;

      @include mq($from: desktop) {
        margin-top: 18px;
      }
    }

    &-info {
      max-width: 516px;
      width: 100%;

      &-row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 36px;
      }

      &-block {
        margin-left: 48px;

        @include mq($from: desktop) {
          margin-left: 84px;
        }

        &:first-child {
          margin-left: 0;
        }
      }

      &-heading,
      &-value {
        font-size: 18px;
      }

      &-heading {
        margin-bottom: 16px;
      }

      a {
        color: white;
      }
    }

    &-body {
      flex: 1;

      &-copy {
        font-size: 18px;
        margin-bottom: 36px;

        &:last-child {
          margin-bottom: 48px;

          @include mq($from: desktop) {
            margin-bottom: 60px;
          }

          @include mq($from: wide) {
            margin-bottom: 84px;
          }
        }
      }
    }

    &-highlights {
      list-style-type: disc;
      margin-left: 17px;
      margin-bottom: 36px;
    }

    &-highlight {
      font-size: 18px;
      margin-bottom: 6px;
    }
  }
}
