.about {
  align-items: center;
  background: rgba(black, 0.86);
  display: flex;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  transition: all 0.6s var(--ease-out-cubic);
  visibility: hidden;
  width: 100vw;
  z-index: 10;

  .common-wrapper {
    padding: 0;
  }

  &--open {
    opacity: 1;
    transform: none;
    visibility: visible;

    .about-wrapper {
      opacity: 1;
      transform: none;
    }
  }

  &-wrapper {
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    opacity: 0;
    overflow-y: scroll;
    padding: 120px 30px 0;
    transform: translateY(-10%);
    transition: all 0.8s 0.5s var(--ease-out-cubic);

    @include mq($from: desktop) {
      align-items: center;
      display: flex;
      justify-content: space-around;
      margin: auto;
      padding: 60px 30px;
    }
  }

  &-left {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    @include mq($from: desktop) {
      margin-bottom: 0;
    }
  }

  &-pic {
    border-radius: 50%;
    height: 192px;
    width: 192px;

    @include mq($from: desktop) {
      height: 264px;
      width: 264px;
    }
  }

  &-right {
    margin: 0 auto 132px;
    max-width: 600px;

    @include mq($from: desktop) {
      margin: 0 0 84px;
    }
  }

  &-bio {
    margin: auto;
    max-width: 600px;
  }

  &-subheading {
    font-size: 22px;
    margin-bottom: 24px;

    @include mq($from: desktop) {
      font-size: 30px;
      line-height: 1.4;
    }
  }

  &-copy {
    font-size: 18px;
    margin-bottom: 24px;

    @include mq($from: desktop) {
      font-size: 20px;
    }

    a {
      color: white;
    }
  }

  &-plug {
    padding-top: 24px;
  }

  &-btn {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    right: 18px;
    text-decoration: none;
    top: 24px;
    transition: all 0.25s var(--ease-out-cubic);

    &:hover {
      opacity: 0.75;
    }

    @include mq($from: desktop) {
      right: 36px;
      top: 36px;
    }
  }
}
